<script>
  export let doubleBounce = false;
</script>

<div class="spinner">
  <div class="spinner__wrapper">
    {#if doubleBounce}
      <div class="spinner__double-bounce spinner__double-bounce--1"></div>
      <div class="spinner__double-bounce spinner__double-bounce--2"></div>
    {:else}
      <div class="spinner__cube-grid">
        <div class="spinner__cube spinner__cube--1"></div>
        <div class="spinner__cube spinner__cube--2"></div>
        <div class="spinner__cube spinner__cube--3"></div>
        <div class="spinner__cube spinner__cube--4"></div>
        <div class="spinner__cube spinner__cube--5"></div>
        <div class="spinner__cube spinner__cube--6"></div>
        <div class="spinner__cube spinner__cube--7"></div>
        <div class="spinner__cube spinner__cube--8"></div>
        <div class="spinner__cube spinner__cube--9"></div>
      </div>
    {/if}
  </div>
  <slot></slot>
</div>

<style src="_index.scss">.spinner {
  display: inline-block;
  text-align: center; }
  .spinner__wrapper {
    display: inline-block;
    position: relative; }
  .spinner__wrapper, .spinner__cube-grid {
    width: 40px;
    height: 40px; }
  .spinner__cube {
    width: 33%;
    height: 33%;
    background-color: #0083c2;
    float: left;
    animation: cubeGridScaleDelay 1.3s infinite ease-in-out; }
    .spinner__cube--1 {
      animation-delay: 0.2s; }
    .spinner__cube--2 {
      animation-delay: 0.3s; }
    .spinner__cube--3 {
      animation-delay: 0.4s; }
    .spinner__cube--4 {
      animation-delay: 0.1s; }
    .spinner__cube--5 {
      animation-delay: 0.2s; }
    .spinner__cube--6 {
      animation-delay: 0.3s; }
    .spinner__cube--7 {
      animation-delay: 0s; }
    .spinner__cube--8 {
      animation-delay: 0.1s; }
    .spinner__cube--9 {
      animation-delay: 0.2s; }
  .spinner__double-bounce--1, .spinner__double-bounce--2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #0083c2;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2s infinite ease-in-out; }
  .spinner__double-bounce--2 {
    animation-delay: -1s; }

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

@-webkit-keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1); }
  35% {
    transform: scale3D(0, 0, 1); } }

@keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1); }
  35% {
    transform: scale3D(0, 0, 1); } }</style>
