import { parseUrl } from 'query-string';
import {
  init, getLocaleFromNavigator, getLocaleFromQueryString, getLocaleFromHash, locale, register,
} from 'svelte-i18n';

export const LocalesEnum = Object.freeze({
  EN: 'en',
  IT: 'it',
  DE: 'de',
  FR: 'fr',
  ES: 'es',
});

export const setLocale = language => {
  if (!language && !Object.values(LocalesEnum).includes(language)) {
    return;
  }

  locale.set(language);
};

const getLocale = () => {
  const { locale: lang } = parseUrl(window.location.hash.replace('#', '')).query;

  return lang || getLocaleFromHash('locale') || getLocaleFromQueryString('locale') || getLocaleFromNavigator();
};

/**
 * Init locales asynchronously
 *
 * @see https://github.com/kaisermann/svelte-i18n/blob/master/docs/Getting%20Started.md#32-asynchronous
 *
 * @param {string} view
 */
export const initLocalizationAsync = view => {
  if (!view) {
    return Promise.reject();
  }

  const promises = [];

  Object
    .values(LocalesEnum)
    .forEach(item => {
      const promise = import(/* webpackChunkName: "locale-[request]" */ `@/i18n/locales/${view}/${item}.json`);

      promises.push(promise);

      register(item, () => promise);
    });

  init({
    fallbackLocale: LocalesEnum.EN,
    initialLocale: getLocale(),
  });

  return Promise.allSettled(promises);
};
