<script>
  import { isLoading as localeIsLoading } from 'svelte-i18n';
  import Router from 'svelte-spa-router';
  import routes, { RoutesPathEnum } from '@/router';
  import { initLocalizationAsync } from '@/i18n';

  export let publicPath;

  if (publicPath) {
    // https://webpack.js.org/guides/public-path/#on-the-fly
    // eslint-disable-next-line no-undef, camelcase
    __webpack_public_path__ = publicPath;
  }

  initLocalizationAsync('common');

  routes[RoutesPathEnum.HOME].props = { ...(routes[RoutesPathEnum.HOME].props || {}), ...$$props };
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Material+Icons&display=swap" />
</svelte:head>

<div class="app">
  {#if !$localeIsLoading}
    <Router {routes} restoreScrollState />
  {/if}
</div>

<style src="_index.scss">.app {
  font-size: 1em;
  font-family: Lato, sans-serif;
  color: #212121; }
  .app :global(.notifications) {
    position: relative;
    z-index: 1; }</style>
