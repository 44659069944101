import { wrap } from 'svelte-spa-router/wrap';
import loadingComponent from '@Components/pages/Loading';

export const loadView = view => () => import(/* webpackChunkName: "view-[request]" */ `@Views/${view}/${view}.svelte`);
export const RoutesPathEnum = Object.freeze({
  HOME: '/',
  WEBVIEW: '/webviews/:id',
  CATCH_ALL: '*',
});

export default {
  [RoutesPathEnum.HOME]: wrap({
    asyncComponent: loadView('Playbook'),
    loadingComponent,
  }),
  [RoutesPathEnum.WEBVIEW]: wrap({
    asyncComponent: loadView('WebView'),
    loadingComponent,
  }),
  [RoutesPathEnum.CATCH_ALL]: wrap({
    asyncComponent: loadView('404'),
    loadingComponent,
  }),
};
