import camelcase from 'camelcase';
import { parse } from 'query-string';
import App from '@/App';

export default (() => {
  const target = document.getElementById('chatbot-playbook');

  if (!target) {
    return;
  }

  const { attributes } = target;
  const props = Object.keys(attributes).reduce(
    (properties, key) => ({
      [camelcase(attributes[key].name)]: attributes[key].value,
      ...properties,
    }),
    parse(window.location.search) || {}
  );

  const app = new App({ target, props });

  window.app = app;
})();
