<script>
  import Spinner from '@Components/atoms/Spinner';

  export let loading = false;
  export let fixed = false;
  export let doubleBounce = false;
</script>

<div
  class="loading-wrapper"
  class:loading-wrapper--loading="{loading}"
  class:loading-wrapper--fixed="{fixed}">
  <slot />
  <div class="loading-wrapper__spinner">
    <Spinner {doubleBounce}>
      <slot name="loading-text"></slot>
    </Spinner>
  </div>
</div>

<style src="_index.scss">.loading-wrapper {
  position: relative; }
  .loading-wrapper__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }
  .loading-wrapper--fixed > .loading-wrapper__spinner {
    position: fixed;
    top: 50vh;
    transform: translate(-50%, -100%); }
  .loading-wrapper:not(.loading-wrapper--loading) > .loading-wrapper__spinner {
    display: none; }
  .loading-wrapper--loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.7); }</style>
